exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-studio-js": () => import("./../../../src/pages/studio.js" /* webpackChunkName: "component---src-pages-studio-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/About.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-archive-js": () => import("./../../../src/templates/Archive.js" /* webpackChunkName: "component---src-templates-archive-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/Article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-director-js": () => import("./../../../src/templates/Director.js" /* webpackChunkName: "component---src-templates-director-js" */),
  "component---src-templates-directors-js": () => import("./../../../src/templates/Directors.js" /* webpackChunkName: "component---src-templates-directors-js" */),
  "component---src-templates-pony-show-js": () => import("./../../../src/templates/PonyShow.js" /* webpackChunkName: "component---src-templates-pony-show-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/Post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-production-js": () => import("./../../../src/templates/Production.js" /* webpackChunkName: "component---src-templates-production-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/Project.js" /* webpackChunkName: "component---src-templates-project-js" */)
}

